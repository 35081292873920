<template>
	<ion-content :fullscreen="true">
		<div id="invite">
			<form class="login-form" @submit.prevent="acceptInvite()">
				<ion-img :src="require('../assets/img/logo.svg')" />
				<p>Bitte suche Dir ein Passwort aus:</p>
				<ion-item>
					<ion-label class="ion-margin-end">
						<ion-icon :icon="lockClosedOutline"></ion-icon>
					</ion-label>
					<ion-input
						required
						v-model="password"
						type="password"
						placeholder="Passwort"
					></ion-input>
				</ion-item>
				<ion-item>
					<ion-label class="ion-margin-end">
						<ion-icon :icon="lockClosedOutline"></ion-icon>
					</ion-label>
					<ion-input
						required
						v-model="password2"
						type="password"
						placeholder="Passwort wiederholen"
					></ion-input>
				</ion-item>
				<ion-note
					class="ion-margin-top"
					v-if="password.length < 8 || password !== password2"
				>
					<span v-if="password === ''"></span>
					<span v-else-if="password.length < 8"
						>Bitte verwende mindestens 8 Zeichen mit Groß- und
						Kleinbuchstaben</span
					>
					<span v-else-if="password !== password2"
						>Passwörter stimmen nicht überein</span
					>
				</ion-note>
				<ion-button
					expand="full"
					type="submit"
					color="black"
					class="ion-margin-top"
					:disabled="
						password === '' ||
						password.length < 8 ||
						password !== password2
					"
				>
					Account erstellen
					<ion-icon slot="end" :icon="logInOutline"></ion-icon>
				</ion-button>
			</form>
		</div>
		<ion-alert
			:is-open="isOpenRef"
			header="🙈 Ohoh!"
			sub-header="Das hat nicht geklappt:"
			:message="errorMsg"
			:buttons="['Ok']"
			@didDismiss="setOpen(false)"
		>
		</ion-alert>
		<ion-alert
			:is-open="isOpenSucc"
			header="🎉 Vielen Dank!"
			sub-header="Du kannst Dich jetzt anmelden"
			:buttons="[
				{
					text: 'Zum Login',
					handler: () => goToLogin(),
				},
			]"
			@didDismiss="setOpenSucc(false)"
		>
		</ion-alert>
	</ion-content>
</template>

<script>
import {
	IonContent,
	IonLabel,
	IonInput,
	IonItem,
	IonButton,
	IonIcon,
	IonAlert,
	IonNote,
} from '@ionic/vue';
import { logInOutline, mailOutline, lockClosedOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';

export default defineComponent({
	name: 'Invite',
	components: {
		IonContent,
		IonLabel,
		IonInput,
		IonItem,
		IonButton,
		IonIcon,
		IonAlert,
		IonNote,
	},
	data() {
		return {
			email: '',
			password: '',
			password2: '',
			errorMsg: '',
		};
	},
	setup() {
		const isOpenRef = ref(false);
		const isOpenSucc = ref(false);
		const setOpen = (state) => (isOpenRef.value = state);
		const setOpenSucc = (state) => (isOpenSucc.value = state);
		return {
			logInOutline,
			mailOutline,
			lockClosedOutline,
			isOpenRef,
			isOpenSucc,
			setOpen,
			setOpenSucc,
		};
	},
	created() {
		if (this.$store.getters.isLoggedIn) {
			console.log('logout');
			this.$store.dispatch('updateLoading', true);
			this.$store
				.dispatch('logout')
				.then(() => {
					this.$store.dispatch('updateLoading', false);
					this.$router.go();
				})
				.catch((err) => console.log(err));
		}
	},
	methods: {
		async acceptInvite() {
			console.log('accept');
			let token = this.$route.query.token;
			let password = this.password;
			this.$store.dispatch('updateLoading', true);
			try {
				let accept = await this.$http.post(`/users/invite/accept`, {
					password,
					token,
				});

				// TODO: make nice success & failure message
				if (accept && accept.status === 204) {
					this.$store.dispatch('updateLoading', false);
					this.setOpenSucc(true);
				} else {
					this.errorMsg = 'Unable to set password: ' + accept.status;
					this.setOpen(true);
				}
			} catch (e) {
				this.errorMsg = 'Unable to set password';
				this.setOpen(true);
				this.$store.dispatch('updateLoading', false);
				console.log(e);
			}
		},
		goToLogin() {
			console.log('to login');
			window.location = 'https://biohandel.app/login';
		},
	},
});
</script>

<style lang="scss" scoped>
#invite {
	display: flex;
	align-items: center !important;
	justify-content: center !important;
	width: 100%;
	height: 100%;
	background: url(/assets/img/carrots.jpg);
	background-position: center center;
	background-size: cover;
	.login-form {
		width: 350px;
		max-width: 80vw;
		margin-top: -50px;
		background-color: white;
		padding: 20px;
		filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.249508));
		display: flex;
		flex-direction: column;

		ion-img {
			height: 6.5rem;
			margin-bottom: 15px;
			margin-top: 15px;
		}

		h1 {
			font-weight: bold;
			text-align: center;
			margin-bottom: 20px;
			span {
				color: var(--ion-color-primary);
			}
		}

		.button {
			margin-top: 35px;
		}

		ion-note {
			cursor: pointer;
			text-align: center;
			margin-top: 10px;
			font-size: 12px;
		}
	}
}
</style>
